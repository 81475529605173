import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell } from 'recharts';

// Define types
type FinancialData = {
  year: number;
  users: number;
  newUsers: number;
  churnedUsers: number;
  revenue: number;
  subscriptionRevenue: number;
  inAppPurchases: number;
  costs: number;
  marketingCosts: number;
  developmentCosts: number;
  operationalCosts: number;
  profit: number;
  cac: number;
  ltv: number;
  arpu: number;
};

type InputData = {
  initialUsers: number;
  userGrowthRate: number;
  churnRate: number;
  subscriptionPrice: number;
  inAppPurchaseRate: number;
  averageInAppPurchase: number;
  costPerUser: number;
  marketingCostPerNewUser: number;
  fixedCosts: number;
  developmentCosts: number;
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const FinancialModel: React.FC = () => {
  const [inputData, setInputData] = useState<InputData>({
    initialUsers: 1500,
    userGrowthRate: 0.5,
    churnRate: 0.15,
    subscriptionPrice: 4.99,
    inAppPurchaseRate: 0.1,
    averageInAppPurchase: 2.99,
    costPerUser: 0.5,
    marketingCostPerNewUser: 2,
    fixedCosts: 10000,
    developmentCosts: 15000,
  });

  const [financialData, setFinancialData] = useState<FinancialData[]>([]);

  const calculateFinancialData = (inputs: InputData): FinancialData[] => {
    const data: FinancialData[] = [];
    let currentUsers = inputs.initialUsers;

    for (let year = 1; year <= 5; year++) {
      const newUsers = Math.round(currentUsers * inputs.userGrowthRate);
      const churnedUsers = Math.round(currentUsers * inputs.churnRate);
      const users = currentUsers + newUsers - churnedUsers;
      
      const subscriptionRevenue = users * inputs.subscriptionPrice * 12;
      const inAppPurchases = users * inputs.inAppPurchaseRate * inputs.averageInAppPurchase * 12;
      const revenue = subscriptionRevenue + inAppPurchases;
      
      const marketingCosts = newUsers * inputs.marketingCostPerNewUser;
      const operationalCosts = users * inputs.costPerUser * 12 + inputs.fixedCosts * 12;
      const costs = marketingCosts + operationalCosts + inputs.developmentCosts * 12;
      
      const profit = revenue - costs;
      
      const cac = marketingCosts / newUsers;
      const arpu = revenue / users;
      const ltv = arpu * (1 / inputs.churnRate);

      data.push({
        year,
        users,
        newUsers,
        churnedUsers,
        revenue,
        subscriptionRevenue,
        inAppPurchases,
        costs,
        marketingCosts,
        developmentCosts: inputs.developmentCosts * 12,
        operationalCosts,
        profit,
        cac,
        ltv,
        arpu,
      });
      
      currentUsers = users;
    }

    return data;
  };

  useEffect(() => {
    setFinancialData(calculateFinancialData(inputData));
  }, [inputData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputData(prev => ({ ...prev, [name]: parseFloat(value) }));
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">PurrWalk Detailed Financial Model</h1>
      
      <div className="grid grid-cols-2 gap-4 mb-8">
        <div>
          <label className="block mb-2">Initial Users</label>
          <input
            type="number"
            name="initialUsers"
            value={inputData.initialUsers}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
          <p className="text-sm text-gray-600 mt-1">Number of users at the start of Year 1</p>
        </div>
        <div>
          <label className="block mb-2">User Growth Rate (annual)</label>
          <input
            type="number"
            name="userGrowthRate"
            value={inputData.userGrowthRate}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            step="0.01"
          />
          <p className="text-sm text-gray-600 mt-1">Expected annual growth rate of new users</p>
        </div>
        <div>
          <label className="block mb-2">Churn Rate (annual)</label>
          <input
            type="number"
            name="churnRate"
            value={inputData.churnRate}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            step="0.01"
          />
          <p className="text-sm text-gray-600 mt-1">Percentage of users expected to leave annually</p>
        </div>
        <div>
          <label className="block mb-2">Subscription Price (monthly)</label>
          <input
            type="number"
            name="subscriptionPrice"
            value={inputData.subscriptionPrice}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            step="0.01"
          />
          <p className="text-sm text-gray-600 mt-1">Monthly subscription fee per user</p>
        </div>
        <div>
          <label className="block mb-2">In-App Purchase Rate</label>
          <input
            type="number"
            name="inAppPurchaseRate"
            value={inputData.inAppPurchaseRate}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            step="0.01"
          />
          <p className="text-sm text-gray-600 mt-1">Percentage of users making in-app purchases monthly</p>
        </div>
        <div>
          <label className="block mb-2">Average In-App Purchase</label>
          <input
            type="number"
            name="averageInAppPurchase"
            value={inputData.averageInAppPurchase}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            step="0.01"
          />
          <p className="text-sm text-gray-600 mt-1">Average value of each in-app purchase</p>
        </div>
        <div>
          <label className="block mb-2">Cost Per User (monthly)</label>
          <input
            type="number"
            name="costPerUser"
            value={inputData.costPerUser}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            step="0.01"
          />
          <p className="text-sm text-gray-600 mt-1">Monthly operational cost per user (e.g., server costs)</p>
        </div>
        <div>
          <label className="block mb-2">Marketing Cost Per New User</label>
          <input
            type="number"
            name="marketingCostPerNewUser"
            value={inputData.marketingCostPerNewUser}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            step="0.01"
          />
          <p className="text-sm text-gray-600 mt-1">Cost to acquire each new user</p>
        </div>
        <div>
          <label className="block mb-2">Fixed Costs (monthly)</label>
          <input
            type="number"
            name="fixedCosts"
            value={inputData.fixedCosts}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
          <p className="text-sm text-gray-600 mt-1">Monthly fixed operational costs</p>
        </div>
        <div>
          <label className="block mb-2">Development Costs (monthly)</label>
          <input
            type="number"
            name="developmentCosts"
            value={inputData.developmentCosts}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          />
          <p className="text-sm text-gray-600 mt-1">Monthly costs for ongoing app development and maintenance</p>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Financial Forecast</h2>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border">
            <thead>
              <tr className="bg-gray-200">
                <th className="border p-2">Year</th>
                <th className="border p-2">Users</th>
                <th className="border p-2">New Users</th>
                <th className="border p-2">Churn</th>
                <th className="border p-2">Revenue</th>
                <th className="border p-2">Costs</th>
                <th className="border p-2">Profit</th>
                <th className="border p-2">CAC</th>
                <th className="border p-2">LTV</th>
                <th className="border p-2">ARPU</th>
              </tr>
            </thead>
            <tbody>
              {financialData.map((data) => (
                <tr key={data.year}>
                  <td className="border p-2">{data.year}</td>
                  <td className="border p-2">{data.users.toLocaleString()}</td>
                  <td className="border p-2">{data.newUsers.toLocaleString()}</td>
                  <td className="border p-2">{data.churnedUsers.toLocaleString()}</td>
                  <td className="border p-2">${data.revenue.toLocaleString()}</td>
                  <td className="border p-2">${data.costs.toLocaleString()}</td>
                  <td className="border p-2">${data.profit.toLocaleString()}</td>
                  <td className="border p-2">${data.cac.toFixed(2)}</td>
                  <td className="border p-2">${data.ltv.toFixed(2)}</td>
                  <td className="border p-2">${data.arpu.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-8 mb-8">
        <div>
          <h3 className="text-xl font-bold mb-4">Revenue and Profit Forecast</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={financialData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="revenue" stroke="#8884d8" name="Revenue" />
              <Line type="monotone" dataKey="profit" stroke="#82ca9d" name="Profit" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-4">User Growth Forecast</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={financialData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="users" fill="#8884d8" name="Total Users" />
              <Bar dataKey="newUsers" fill="#82ca9d" name="New Users" />
              <Bar dataKey="churnedUsers" fill="#ffc658" name="Churned Users" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-8 mb-8">
        <div>
          <h3 className="text-xl font-bold mb-4">Revenue Breakdown (Year 5)</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={[
                  { name: 'Subscription', value: financialData[4]?.subscriptionRevenue },
                  { name: 'In-App Purchases', value: financialData[4]?.inAppPurchases },
                ]}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label
              >
                {financialData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div>
          <h3 className="text-xl font-bold mb-4">Cost Breakdown (Year 5)</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={[
                  { name: 'Marketing', value: financialData[4]?.marketingCosts },
                  { name: 'Development', value: financialData[4]?.developmentCosts },
                  { name: 'Operational', value: financialData[4]?.operationalCosts },
                ]}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label
              >
                {financialData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

       <div className="mb-8">
        <h3 className="text-xl font-bold mb-4">Key Metrics Explanation</h3>
        <ul className="list-disc list-inside space-y-2">
          <li><strong>CAC (Customer Acquisition Cost):</strong> The cost to acquire a new user. Calculated as total marketing costs divided by the number of new users.</li>
          <li><strong>LTV (Lifetime Value):</strong> The total revenue expected from a user throughout their relationship with PurrWalk. Calculated as ARPU divided by churn rate.</li>
          <li><strong>ARPU (Average Revenue Per User):</strong> The average revenue generated per user annually. Calculated as total revenue divided by the number of users.</li>
          <li><strong>Churn Rate:</strong> The percentage of users who stop using PurrWalk within a year.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-bold mb-4">Financial Analysis</h3>
        <div className="space-y-4">
          <p><strong>Revenue Growth:</strong> The model projects a {((financialData[4]?.revenue / financialData[0]?.revenue - 1) * 100).toFixed(2)}% increase in revenue over 5 years, driven by user growth and in-app purchases.</p>
          
          <p><strong>Profitability:</strong> PurrWalk is expected to {financialData[4]?.profit > 0 ? 'be profitable' : 'incur losses'} by Year 5, with a projected profit of ${financialData[4]?.profit.toLocaleString()}.</p>
          
          <p><strong>User Acquisition:</strong> The CAC in Year 5 is ${financialData[4]?.cac.toFixed(2)}, while the LTV is ${financialData[4]?.ltv.toFixed(2)}. This {financialData[4]?.ltv > financialData[4]?.cac ? 'favorable' : 'unfavorable'} LTV/CAC ratio of {(financialData[4]?.ltv / financialData[4]?.cac).toFixed(2)} indicates that {financialData[4]?.ltv > financialData[4]?.cac ? 'the company is efficiently acquiring users' : 'the company needs to improve its user acquisition strategy or reduce costs'}.</p>
          
          <p><strong>Revenue Sources:</strong> By Year 5, subscription revenue accounts for {((financialData[4]?.subscriptionRevenue / financialData[4]?.revenue) * 100).toFixed(2)}% of total revenue, with in-app purchases making up the remaining {((financialData[4]?.inAppPurchases / financialData[4]?.revenue) * 100).toFixed(2)}%.</p>
          
          <p><strong>Cost Structure:</strong> In Year 5, marketing costs represent {((financialData[4]?.marketingCosts / financialData[4]?.costs) * 100).toFixed(2)}% of total costs, while development and operational costs account for {((financialData[4]?.developmentCosts / financialData[4]?.costs) * 100).toFixed(2)}% and {((financialData[4]?.operationalCosts / financialData[4]?.costs) * 100).toFixed(2)}% respectively.</p>
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-bold mb-4">Sensitivity Analysis</h3>
        <p className="mb-4">Below is a simple sensitivity analysis showing how changes in key variables affect the Year 5 profit:</p>
        <table className="w-full border-collapse border">
          <thead>
            <tr className="bg-gray-200">
              <th className="border p-2">Variable</th>
              <th className="border p-2">-10%</th>
              <th className="border p-2">Base Case</th>
              <th className="border p-2">+10%</th>
            </tr>
          </thead>
          <tbody>
            {['userGrowthRate', 'churnRate', 'subscriptionPrice', 'marketingCostPerNewUser'].map((variable) => {
              const baseCase = calculateFinancialData(inputData)[4].profit;
              const lowCase = calculateFinancialData({...inputData, [variable]: inputData[variable as keyof InputData] * 0.9})[4].profit;
              const highCase = calculateFinancialData({...inputData, [variable]: inputData[variable as keyof InputData] * 1.1})[4].profit;
              return (
                <tr key={variable}>
                  <td className="border p-2">{variable}</td>
                  <td className="border p-2">${lowCase.toLocaleString()}</td>
                  <td className="border p-2">${baseCase.toLocaleString()}</td>
                  <td className="border p-2">${highCase.toLocaleString()}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-bold mb-4">Assumptions and Limitations</h3>
        <ul className="list-disc list-inside space-y-2">
          <li>The model assumes a constant growth rate and churn rate over the 5-year period, which may not reflect real-world fluctuations.</li>
          <li>It doesn't account for potential changes in pricing strategy or cost structure over time.</li>
          <li>The model doesn't consider external factors such as competition, market saturation, or economic conditions.</li>
          <li>In-app purchase behavior is simplified and may not capture the full complexity of user spending patterns.</li>
          <li>The model doesn't account for potential economies of scale in costs as the user base grows.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-bold mb-4">Recommendations</h3>
        <ul className="list-disc list-inside space-y-2">
          <li>Focus on reducing churn rate to improve LTV and overall profitability.</li>
          <li>Explore opportunities to increase the in-app purchase rate and average purchase value.</li>
          <li>Monitor and optimize marketing spend to improve CAC while maintaining growth.</li>
          <li>Consider introducing premium features or tiered pricing to increase ARPU.</li>
          <li>Regularly review and adjust the financial model based on actual performance data.</li>
        </ul>
      </div>
    </div>
  );
};

export default FinancialModel;