import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { motion } from 'framer-motion';

// Sample data for the traction chart
const tractionData = [
  { month: 'Jan', downloads: 300 },
  { month: 'Feb', downloads: 600 },
  { month: 'Mar', downloads: 900 },
  { month: 'Apr', downloads: 1200 },
  { month: 'May', downloads: 1500 },
];

const Slide = ({ title, content, currentSlide, totalSlides }: { title: string; content: React.ReactNode; currentSlide: number; totalSlides: number }) => (
  <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    className="bg-black text-white p-8 rounded-lg shadow-lg w-full h-[80vh] flex flex-col justify-between"
  >
    <div>
      <h2 className="text-3xl font-bold mb-6 text-gray-100 border-b border-gray-700 pb-2">{title}</h2>
      <div className="text-gray-300">{content}</div>
    </div>
    <div className="text-right text-gray-500">
      Page {currentSlide + 1} of {totalSlides}
    </div>
  </motion.div>
);

const BulletPoint = ({ children }: { children: React.ReactNode }) => (
  <motion.li 
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.5 }}
    className="flex items-start mb-2"
  >
    <span className="text-gray-400 mr-2">•</span>
    <span>{children}</span>
  </motion.li>
);

const PitchDeck = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 11;

  const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % totalSlides);
  const prevSlide = () => setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);

  const slides = [
    {
      title: "PurrWalk: Redefining City Exploration",
      content: (
        <div className="text-center">
          <p className="text-2xl mb-6 text-gray-300 font-semibold">Next-generation GPS-based audio tours</p>
          <p className="text-xl text-gray-400">Discover cities like never before</p>
        </div>
      )
    },
    {
      title: "The Problem",
      content: (
        <ul className="space-y-4">
          <BulletPoint>Solo travelers struggle to discover lesser-known parts of cities</BulletPoint>
          <BulletPoint>Existing audio guides often require purchasing specific routes</BulletPoint>
          <BulletPoint>Lack of flexible, engaging options for city exploration</BulletPoint>
        </ul>
      )
    },
    {
      title: "Our Solution",
      content: (
        <div>
          <p className="mb-6 text-xl text-gray-300 font-semibold">PurrWalk: The freedom to explore at your own pace</p>
          <ul className="space-y-4">
            <BulletPoint>GPS-based audio tours without predefined routes</BulletPoint>
            <BulletPoint>Gamified design for an engaging experience</BulletPoint>
            <BulletPoint>Subscription model for easy access to multiple cities</BulletPoint>
          </ul>
        </div>
      )
    },
    {
      title: "Product Overview",
      content: (
        <div>
          <p className="mb-6 text-xl text-gray-300 font-semibold">Key Features:</p>
          <ul className="space-y-4">
            <BulletPoint>iOS app (Android version in development)</BulletPoint>
            <BulletPoint>900+ points of interest in Amsterdam, 1000+ in Rome</BulletPoint>
            <BulletPoint>AI-generated content with human-quality control</BulletPoint>
            <BulletPoint>High-quality voice generation (ElevenLabs partnership)</BulletPoint>
          </ul>
        </div>
      )
    },
    {
      title: "Market Opportunity",
      content: (
        <div>
          <p className="mb-6 text-xl text-gray-300 font-semibold">Target Audience:</p>
          <ul className="space-y-4">
            <BulletPoint>Solo travelers</BulletPoint>
            <BulletPoint>Business travelers</BulletPoint>
            <BulletPoint>Local history enthusiasts</BulletPoint>
          </ul>
          <p className="mt-6 text-lg text-gray-400">Potential to expand into museum guides and personal travel assistance</p>
        </div>
      )
    },
    {
      title: "Traction",
      content: (
        <div>
          <p className="mb-6 text-xl text-gray-300 font-semibold">Our Growth:</p>
          <ul className="space-y-4 mb-6">
            <BulletPoint>1,500 downloads (50% organic, 50% from ads)</BulletPoint>
            <BulletPoint>70% retention rate (7 out of 10 users listen to 5+ tracks)</BulletPoint>
          </ul>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={tractionData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#444" />
              <XAxis dataKey="month" stroke="#888" />
              <YAxis stroke="#888" />
              <Tooltip contentStyle={{ backgroundColor: '#333', border: 'none' }} />
              <Legend />
              <Line type="monotone" dataKey="downloads" stroke="#fff" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )
    },
    {
      title: "Business Model",
      content: (
        <div>
          <p className="mb-6 text-xl text-gray-300 font-semibold">Revenue Streams:</p>
          <ul className="space-y-4">
            <BulletPoint>Subscription-based (monthly or lifetime deal per city)</BulletPoint>
            <BulletPoint>Exploring ticket sales integration (Tiquets API)</BulletPoint>
          </ul>
          <p className="mt-6 text-lg text-gray-400">
          For detailed financial projections, view our{' '}
            <a href="/financial-model" className="text-blue-400 hover:underline">interactive financial model</a>.
          </p>
        </div>
      )
    },
    {
      title: "Competitive Advantage",
      content: (
        <ul className="space-y-4">
          <BulletPoint>Quick city content creation (couple of days per city)</BulletPoint>
          <BulletPoint>Engaging, gamified design</BulletPoint>
          <BulletPoint>Easy to start and try</BulletPoint>
          <BulletPoint>Scalable AI-powered content generation</BulletPoint>
        </ul>
      )
    },
    {
      title: "Team",
      content: (
        <div>
          <h3 className="text-2xl font-bold mb-4 text-gray-300">Ilya Golubev - Founder</h3>
          <ul className="space-y-4">
            <BulletPoint>10 years M&A and data analysis experience (KPMG)</BulletPoint>
            <BulletPoint>Self-taught full-stack developer</BulletPoint>
            <BulletPoint>Rapidly acquired diverse skills: coding, AI, marketing, sound editing</BulletPoint>
          </ul>
        </div>
      )
    },
    {
      title: "Vision",
      content: (
        <ul className="space-y-4">
          <BulletPoint>Expand to more cities globally</BulletPoint>
          <BulletPoint>Evolve into a comprehensive personal travel assistant</BulletPoint>
          <BulletPoint>Revolutionize how people explore and experience cities</BulletPoint>
        </ul>
      )
    },
    {
      title: "Ask",
      content: (
        <ul className="space-y-4">
          <BulletPoint>Seeking investment to accelerate growth and product development</BulletPoint>
          <BulletPoint>Expand marketing efforts and user acquisition</BulletPoint>
          <BulletPoint>Fund development of Android version and new features</BulletPoint>
        </ul>
      )
    }
  ];

  return (
    <div className="bg-gray-900 p-8 min-h-screen flex flex-col justify-center items-center">
      <div className="w-full max-w-4xl">
        <Slide
          title={slides[currentSlide].title}
          content={slides[currentSlide].content}
          currentSlide={currentSlide}
          totalSlides={totalSlides}
        />
      </div>
      <div className="flex justify-between w-full max-w-4xl mt-8">
        <button
          onClick={prevSlide}
          className="bg-gray-800 text-white px-6 py-2 rounded-lg hover:bg-gray-700 transition-colors"
        >
          Previous
        </button>
        <button
          onClick={nextSlide}
          className="bg-gray-800 text-white px-6 py-2 rounded-lg hover:bg-gray-700 transition-colors"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PitchDeck;