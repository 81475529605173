import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import PitchDeck from './PitchDeck';
import FinancialModel from './FinancialModel';

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <nav className="bg-gray-800 p-4">
          <ul className="flex space-x-4">
            <li>
              <Link to="/" className="text-white hover:text-gray-300">Pitch Deck</Link>
            </li>
            <li>
              <Link to="/financial-model" className="text-white hover:text-gray-300">Financial Model</Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<PitchDeck />} />
          <Route path="/financial-model" element={<FinancialModel />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;